<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-brand', params: { accountId: this.$route.params.accountId } }">Brands</router-link>
                    <template v-if="brand">
                    &gt;
                    <router-link :to="{ name: 'account-edit-brand', params: { accountId: this.$route.params.accountId, brandId: this.$route.params.brandId } }">{{ brand.name }}</router-link>
                    </template>
                </p>
                </v-col>
            </v-row>
            <!-- <v-row justify="center" class="py-5 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ brand.name }}</h1>
                <p class="text-caption text-center">Brand overview</p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10" v-if="brand">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card>
                        <v-toolbar color="teal" dark flat dense>
                        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                        <v-toolbar-title>
                            Brand Alias
                        </v-toolbar-title>
                        <!-- <template v-if="isDraft">
                            <v-spacer/>
                            <v-btn icon color="white green--text" @click="publish">
                                <font-awesome-icon :icon="['fas', 'cloud-upload-alt']"/>
                            </v-btn>
                        </template> -->
                        <!-- <v-progress-linear
                            :active="emailDispatchStatus && emailDispatchStatus.status === 'started'"
                            :value="emailDispatchProgress"
                            absolute
                            bottom
                            color="green"
                        ></v-progress-linear> -->
                        </v-toolbar>
                        <v-card-text>

                    <p class="text-overline mb-0">Brand Name</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-edit-brand', params: { accountId: this.$route.params.accountId, brandId: this.$route.params.brandId } }">{{ brand.name }}</router-link>
                    </p>

                    <!-- TODO: we should only show ONE primary alias in the the output, and the rest should be in a separate section of alternative aliases that redirect to the primary one  (clients will get 301 Moved Permanently redirecting them to the primary alias when they try to access these alternative aliases at the CDN) so the code below needs to be reworked for this, to show just the primary alias and then if there are any alternative aliases to show a link to view them (maybe in a dialog box) and a count of how many there are next to the link , something like "N old aliases redirect to this one" or "See former alias list (N)" or "Alias redirects (N)" -->
                    <!-- <p class="text-overline mb-0 mt-8">Alias List</p> -->
                        </v-card-text>
                    <v-divider/>

                    <v-list dense nav>
                        <v-list-item v-if="list.length === 0">No aliases yet.</v-list-item>
                        <v-list-item v-for="(item, idx) in list" v-bind:key="idx" :class="itemClass(item)">
                            <!-- <v-list-item-avatar :color="item.hex">
                            </v-list-item-avatar> -->
                            <v-list-item-content class="text-start">
                                <v-list-item-title>
                                    <v-row no-gutters align="center">
                                    <span>{{ item.alias }}</span>

                                    <v-btn icon color="green" :href="linkViewBrandAlias(item.alias)" target="_blank" class="ma-0 pa-0">
                                        <font-awesome-icon :icon="['fas', 'external-link-alt']"/>
                                    </v-btn>

                                    <v-spacer/>
                                    <!-- <span class="text-caption mr-2" v-if="item.alias === brand.alias">Current</span> -->
                                    <span class="text-overline">{{ displayType(item.type) }}</span>
                                    </v-row>
                                </v-list-item-title>
                                <!-- <v-list-item-subtitle>{{ item.type }}</v-list-item-subtitle> -->
                            </v-list-item-content>
                            <!-- <v-list-item-action>
                            <v-menu offset-y left>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon color="green" v-on="on">
                                        <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                                    </v-btn>
                                </template>
                                <v-list class="ma-0 pa-0">
                                    <v-list-item-group>
                                    <v-list-item @click="openDeletePaletteColorDialog(idx, item)">
                                        < ! - - <v-list-item-icon>
                                            <font-awesome-icon :icon="['fas', 'trash']" style="font-size: 20px; color: red" fixed-width/>
                                        </v-list-item-icon> - - >
                                        <v-list-item-content>
                                            <v-list-item-title style="color: red;">Delete...</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-menu>
                            </v-list-item-action> -->
                        </v-list-item>
                    </v-list>

                    <!-- <p class="text-overline mb-0 mt-8">Status</p>
                    <p class="mb-0 pb-0">
                        <span v-if="typeof isPublished === 'boolean' && isPublished">Published</span>
                        <span v-if="typeof isPublished === 'boolean' && !isPublished">Draft</span>
                    </p> -->

                    </v-card>

                    <!-- TODO: the affected X list should depend on the brand type... instead of hard-coding email contacts -->
                    <!-- <p class="text-overline mb-0 mt-8">Status</p>
                    <p class="mb-0 pb-0">
                        <span v-if="affectedEmailContactList.length > 0">
                            This brand is subscribed by {{ affectedEmailContactList.length }} email contacts.
                            < ! - - TODO: show the affectedEmailContactList - - >
                        </span>
                        <span v-if="affectedEmailContactList.length === 0">
                        This brand is not currently applied to any email contacts.
                        </span>
                    </p> -->

                    <!-- <p class="text-overline mb-0 mt-8">Security</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-edit-form-access', params: { accountId: this.$route.params.accountId, brandId: this.$route.params.brandId } }">Access control</router-link>
                    </p> -->
                    <!-- <template v-if="isPermitServiceAdmin">
                    <p class="text-overline mb-0 mt-8">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="green--text"/></p>
                    <p class="mb-0 pb-0">
                    </p> -->
                    <v-expansion-panels class="mt-8" v-ifdev>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>
                                Brand Info <font-awesome-icon :icon="['far', 'code']" class="grey--text"/>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <pre>{{ JSON.stringify(this.brand, null, 2) }}</pre>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>
                                Brand Alias Info <font-awesome-icon :icon="['far', 'code']" class="grey--text"/>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <pre>{{ JSON.stringify(this.list, null, 2) }}</pre>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style>
.v-input .v-input__prepend-outer {
    margin-left: 4px !important;
    padding-left: 4px !important;
}

.v-input .v-input__append-outer {
    margin-top: 0px !important;
    padding-top: 0px !important;
}
</style>

<script>
import { mapState } from 'vuex';
// import BrandList from '@/components/account-dashboard/BrandList.vue';
// import { compact } from '@/sdk/input';
// import { randomText } from '@/sdk/random';

export default {
    components: {
        // BrandList,
    },
    data: () => ({
        account: null,
        brand: null,
        list: [],
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.account !== null && this.brand !== null;
        },
    },
    watch: {
    },
    methods: {
        linkViewBrandAlias(alias) {
            const cdn = process.env.VUE_APP_CDN_WEBSITE_PROFILE_URL ?? 'https://cdn.brandprofile.org/view';
            if (this.brand?.alias) {
                return `${cdn}/${encodeURIComponent(alias)}`;
            }
            return '';
        },
        itemClass(item) {
            if (item.alias === this.brand.alias) {
                return 'green lighten-4'; // ['green', 'lighten-4'];
            }
            return '';
        },
        displayType(type) {
            switch (type) {
            case 'free':
                return 'Free';
            case 'premium':
                return 'Premium';
            default:
                return type;
            }
        },
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`loadAccount: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadBrand() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadBrand: true });
                const response = await this.$client.account(this.$route.params.accountId).brand.get({ id: this.$route.params.brandId });
                console.log(`loadBrand: response ${JSON.stringify(response)}`);
                if (response) {
                    this.brand = response;
                    this.checkAlias();
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load brand');
                }
            } catch (err) {
                console.error('failed to load brand', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadBrand: false });
            }
        },
        async loadBrandAliasList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadBrandAliasList: true });
                const response = await this.$client.account(this.$route.params.accountId).brandAlias.search({ brand_id: this.$route.params.brandId });
                console.log(`loadBrandAliasList: response ${JSON.stringify(response)}`);
                if (response?.list) {
                    this.list = response.list;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load brand alias list');
                }
            } catch (err) {
                console.error('failed to load brand alias list', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadBrandAliasList: false });
            }
        },
        /*
        async save(brandAttr) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveEditBrand: true });
                // TODO: only send what changed -- check input and sequence for changes separately
                const response = await this.$client.account(this.$route.params.accountId).brand.edit({ id: this.$route.params.brandId }, brandAttr);
                console.log(`saveEditBrand: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    return true;
                }
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit brand' });
                return false;
            } catch (err) {
                console.error('failed to edit brand', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit brand' });
                return false;
            } finally {
                this.$store.commit('loading', { saveEditBrand: false });
            }
        },
        async saveEditBrandFreeAlias() {
            const isEdited = await this.save({ free_alias: this.editableBrandFreeAlias });
            this.editBrandFreeAliasDialog = false;
            if (isEdited) {
                // this.$set(this.brand, 'free_alias', this.editableBrandFreeAlias); // we can't just do this because the server adds a suffix... we need to reload the brand info
                this.loadBrand();
            }
        },
        */
        /*
        async checkAlias() {
            try {
                this.error = false;
                this.$store.commit('loading', { checkAlias: true });
                const response = await this.$client.account(this.$route.params.accountId).brand.check({ id: this.$route.params.brandId, item: 'alias' });
                console.log(`checkAlias: response ${JSON.stringify(response)}`);
                // this.isPublished = typeof response?.isPublished === 'boolean' ? response.isPublished : null;
                if (response?.alias !== this.brand?.alias) {
                    this.$set(this.brand, 'alias', response.alias);
                }
            } catch (err) {
                console.error('failed to load brand alias status', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { checkAlias: false });
            }
        },
        */
    },
    mounted() {
        this.loadAccount();
        this.loadBrand();
        this.loadBrandAliasList();
    },
};
</script>
